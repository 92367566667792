<template>
  <div class="empty-page-container">
    <m-empty imgHeight="100px">
      <template #description>
        <a-button @click="toAddPlace" type="primary">
          去添加广告位
        </a-button>
      </template>
    </m-empty>
  </div>
</template>

<script>
export default {
  props: {
    query: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    toAddPlace () {
      this.$router.push({
        path: '/media/adplace',
        query: {
          target: 'adplacen',
          appId: this.query.appId
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.empty-page-container{
  height: 400px;
  border-radius: 5px;
  background-color: #fff;
}
</style>
